*{
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css');

body{
  font-family: "Dosis", sans-serif !important;
  font-size: 16px;
  color: rgba(0,0,0,0.8);
  overflow-x: hidden;
  
}
ul{
  margin-bottom: 0 !important;
  padding-left: 0 !important;
}
.btn-sml{text-transform: capitalize !important; font-size: 13px !important; color: #000 !important; border: 1px solid #000 !important;}
.btn-sml svg{font-size: 20px !important; }
.btn-blue{background: #233a95 !important; color: #fff !important; text-transform: capitalize !important;}
.btn-big{font-size: 15px !important; padding: 7px 30px !important; min-width: auto !important;}
.btn-round{border-radius: 30px !important;}
.badge{display: inline-block; padding: 7px 10px;}
.badge.bg-success {background: #e5f8ed !important; color: #16b858 !important;}
.bg-blue{
  background: #3b433d;
 
}
.cursor{
  cursor:pointer;
}
.text-light{
  color: #8b8b8b !important;
}
.text-sml{
  font-size: 14px;
}
.text-medium{
  font-size: 18px;
}
::-webkit-scrollbar{width: 8px; height: 8px;}
::-webkit-scrollbar-thumb{background: #ccc !important;}
::-webkit-scrollbar-track{background: transparent !important;}
.headerWrapper .top-strip{padding: 8px 0px;}
.headerWrapper .top-strip p{color: #cdc1b6; font-size: 16px; font-weight:500;}
.headerWrapper .header{
  width: 100%; height: auto; padding: 25px 0px;
}
.logoWrapper img{width: 100px; height: 100px;}

.headerWrapper .header .part2 .countryDrop{
  width:170px;height:60px;min-width:150px;
  text-align: left;
  justify-content: flex-start !important;
  color: #000 !important;
  border: 1px solid rgba(0,0,0,0.2)!important;
  border-radius: 7px !important;
 
}
.headerWrapper .header .part2 .countryDrop .label{
  font-size:12px !important;text-transform:capitalize !important;color:rgba(0,0,0,0.4) !important; line-height: 16px;
}
.headerWrapper .header .part2 .countryDrop .name{
  font-size:14px !important;text-transform:capitalize !important;color:#233a95 !important; font-weight: 700; line-height: 16px;
}
.headerWrapper .header .part2 .countryDrop span.ml-auto svg{
  opacity: 0.7;

}
nav{border-bottom: 1px solid rgba(0,0,0,0.1); padding-bottom: 0px;}
.locationModal .MuiPaper-elevation{
  padding: 25px; position: relative;
}
.locationModal .countryList{margin-bottom: 0px; padding-left: 0px; max-height: 400px; overflow-y: scroll; overflow-x: hidden;}
.locationModal .countryList li{width: 100%; list-style: none;}
.locationModal .countryList li button{width: 100%; text-transform: capitalize !important; color: #000; justify-content: flex-start; padding: 15px 20px;}
.locationModal .close_{position: absolute; top:20px; right:20px; color: #000 !important; min-width: 40px; width: 40px;height: 40px;border-radius: 50%;}
.locationModal .close_ svg{font-size: 22px;}
.locationModal .countryList li button:hover{
  background: #f1f1f1 !important;
}
.locationModal .countryList li button .active{
  background: #2bbef9;
}

 .headerSearch{width:52%; height: 60px; background: #ece9e7; padding: 5px; position: relative; border-radius: 5px; border: 1px solid rgba(0,0,0,0.1)}
 .headerSearch input{width:100%; height: 45px; background: transparent !important; outline : none !important; font-size: 18px; padding:0px 20px !important; color: rgba(0,0,0,0.7); border: 0 !important}
 .headerSearch input::-webkit-input-placeholder{color: rgba(0,0,0,0.6);}
 .headerSearch button{position: absolute;top:10px; right: 20px; z-index: 100;min-width: 40px !important;height: 40px !important; width: 40px !important; border-radius: 50% !important; color:#000;border: 0 !important;
  outline: none !important;
  background: #ece9e7 !important;}
 .headerSearch button svg{font-size: 25px; opacity: 0.8;}


header .part3 .circle{ align-items: center; justify-content: center; min-width: 45px !important;height: 45px !important;width: 40px !important; border-radius: 50% !important;border:1px solid rgba(0,0,0,0.1)!important;color: #000;background: #fff;

}
header .part3 .circle svg{font-size: 22px;}
.userTab{
  font-size: 18px; color: rgba(0,0,0,0.6); font-weight: 600;
}
.userTab button{
  background: transparent !important;
}
.cartTab .price{
  font-size: 18px; color: rgba(0,0,0,0.8); font-weight: 700;
}
.cartTab button{
  background: transparent !important;
}
.cartTab button svg{
  stroke: #ea2b0f !important;
}
.cartTab .count{
  position: absolute; top:-6px; right: -4px; background: #ea2b0f; border-radius: 50%; color: #fff; width:20px; height: 20px;
}

nav .allCatTab{ background: #3b433d; padding: 10px 15px !important; border-radius: 30px !important;}
nav .allCatTab *{color:#cdc1b6;}
nav .allCatTab:hover{background:#3b433d;}
nav .allCatTab .text{font-weight:600;}
nav .allCatTab .icon1{font-size:20px;}
nav .allCatTab .icon2{font-size:15px;}

nav .navpart1 .catWrapper{position: relative;}
nav .navpart1 .catWrapper .sidebarNav{width: 300px; height: 0px; opacity:0; background: #fff; position: absolute; top:119%; left:0px; border: 1px solid rgba(0,0,0,0.1); padding: 15px 0px; transition: all 0.2s; visibility: hidden; z-index:100;  }
nav .navpart1 .catWrapper .sidebarNav ul li{width: 100%; list-style: none; padding: 0px 10px;}
nav .navpart1 .catWrapper .sidebarNav ul li button{color:rgba(0, 0, 0, 0.6);font-size: 14px; width:100%; font-weight: 600; justify-content: flex-start; text-transform: capitalize;}
nav .navpart1 .catWrapper .sidebarNav ul li button svg{font-size: 12px;opacity: 0.6;}
nav .navpart1 .catWrapper .sidebarNav ul li button:hover{background: transparent;}
nav .navpart1 .catWrapper .sidebarNav.open{height: auto !important; visibility: inherit !important; opacity: 1 !important;}
nav .navpart1 .catWrapper .sidebarNav ul li {position: static;}
nav .navpart1 .catWrapper .sidebarNav ul li .submenu {position: absolute; top:0px;left:105%; width: 250px; height: 100%; background: #fff; z-index: 100; opacity: 0; visibility: hidden; transition: all 0.2s ease-in-out; border-left: 1px solid rgba(0,0,0,0.1); padding: 20px !important;}
nav .navpart1 .catWrapper .sidebarNav ul li:hover .submenu{opacity: 1; visibility: inherit; left: 100%;}
nav .navpart1 .catWrapper .sidebarNav ul li:hover .submenu button{border-radius: 0px !important;}
nav .napart2 ul{margin-bottom: 0;}
nav .navpart2 ul li {
  padding-bottom: 20px;
}
nav .navpart2 ul li a{text-decoration: none;}
nav .navpart2 ul li a button{font-size: 14px; font-weight: 600;text-decoration: none; color:rgba(0,0,0,0.6); text-transform: uppercase;  padding: 10px 15px; align-items: center; transition: all 0.2s ease-in-out; border-radius: 25px;}

nav .navpart2 ul li a svg{font-size: 15px;}
nav .navpart2 ul li{position: relative;}
nav .navpart2 ul li .submenu{position: absolute; top: 100%; left:0px; min-width:200px; background: #fff; opacity:0; visibility: hidden; transition: all 0.2s ease-in-out; z-index:100 !important;}
nav .navpart2 ul li .submenu a{display: block; width:100%;}
nav .navpart2 ul li .submenu a button{width:100%; justify-content: flex-start; border-radius: 0px; text-transform: capitalize; font-weight: 600;}
nav .navpart2 ul li .submenu a button:hover{background: #f1f1f1 !important;}
nav .navpart2 ul li:hover .submenu{opacity: 1; visibility: inherit; top:100%;}
.homeBannerSection .slick-slide img {
  width: 100%;
 }
.homeBannerSection .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
.homeBannerSection *{outline: none !important;}
.homeBannerSection{position: relative; overflow:inherit;}
.homeBannerSection .swiper{overflow: inherit !important;}
.homeBannerSection .swiper .item{overflow: hidden !important; border-radius: 10px;}
.homeBannerSection .slick-arrow{position: absolute; top:45%; z-index: 100; display: flex; justify-content: center; text-align: center;width: 40px !important; height:40px !important; background: #444; border-radius: 100%;font-family: FontAwesome !important; font-weight: 900; box-shadow: 0 1px 3px rgba(0 0 0 / 12%),0 1px 2px rgba(0 0 0 / 24%)!important ;}
 .slick-next{right: 25px !important;}
 .slick-next::before{font-family: FontAwesome !important; color:#fff;}
.slick-prev{left: 25px !important;}
.slick-prev::before{font-family: FontAwesome !important;  color:#fff;}


/*product section */
.homeSection{
  padding: 25px 0px;
  width: 100%;
  background: #F6F9FC !important;
}
.trendingJobs{
  background-color: #F6F9FC;
  padding: 25px 0px;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  background-color: #2196F3;
  padding: 10px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.homeProducts .banner{
  width: 100%; border-radius: 7px; overflow: hidden;
}
 .hd{font-size: 35px; font-weight: 600; text-transform: uppercase;}
.viewAllBtn{padding: 5px 25px !important; background: #fff; border:1px solid #cdc1b6 !important; border-radius: 30px !important; color:#414141 !important; font-weight: 600; text-transform: capitalize;}
.viewAllBtn svg{font-size:20px;}
.productItem{width:100%;height: auto;border:1px solid rgba(0,0,0,0.1); cursor: pointer; position: relative;}
.productItem .badge{display: inline-block; padding: 7px 10px; position: absolute; top:20px; left:20px; z-index:100;}
.productItem .info{padding: 15px;}
.info .oldPrice{font-size:16px; font-weight: bold; color:rgba(0,0,0,0.2); text-decoration: line-through;}
.info .oldPrice.lg{font-size: 20px !important;}
.info .netPrice{font-size:16px; font-weight: bold;}
.info .netPrice.lg{font-size: 20px !important;}
.productItem .info h4{font-size:15px; font-weight: 600;}
.productItem .actions{position: absolute; top:20px; right:-100px; z-index: 1000; display: flex; flex-direction: column; gap: 10px; transition: all 0.5s ease-in-out;}
.productItem .actions button{display: flex; align-items: center; justify-content: center; min-width: 40px !important; width: 40px !important; height: 40px !important; border: 1px solid rgba(0,0,0,0.1) !important; border-radius: 50% !important; background: #fff !important; box-shadow: 0 1px 3px rgba(0 0 0 / 12%),0 1px 2px rgba(0 0 0 / 24%)!important; transition: al ll 0.0s;}
.productRow .productItem{border-right: 1px solid transparent !important;}
.productItem .actions button:hover{background: #233a95 !important;}
.productItem .imgWrapper{overflow: hidden;}
.productItem .imgWrapper img{transition: all 0.3s ease-in-out;}
.productItem .imgWrapper:hover .actions{opacity: 1 !important; right:10px;}
.productItem .imgWrapper:hover img{transform: scale(1.1);}
.productItem .actions button:hover svg{color: #fff !important;}
.productItem .actions button svg{color: #000 !important; opacity: 0.8; pointer-events: none; font-size: 16px;}
.productRow .swiper-slide:last-child .productItem{border: 1px solid rgba(0,0,0,0.1) !important;}
.swiper{padding-right: 1px !important;}

  .badge-primary{background: #2bbef9 !important;}
  .swiper-button-prev, .swiper-button-next {
    border: 1px solid #ddd !important;
    border-radius: 50%;
    width: 35px !important;
    height: 35px !important;
    background: #fff;
    z-index: 999 !important;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 16px !important;
  color: #000;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 16px !important;
  color: #000;
}

.homeCat{padding: 35px 0px;
  }
.homeCat .swiper{overflow: hidden !important;}


.homeCat .swiper .item img{width: 80%;}
.homeCat  .swiper-button-prev, .homeCat .swiper-button-next {
  border: 1px solid #ddd !important;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  background: #fff;
  top: 30px !important;
}
.homeCat  .swiper-button-prev{right: 68px !important;left: inherit;}
.homeCat .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 12px !important;
  color: #000;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 12px !important;
  color: #000;
}
.productRow2{flex-wrap: wrap; gap:10px;}
.productRow2 .productItem{width: 24%; overflow: hidden; border-right: 1px solid rgba(0,0,0,0.1)!important;}
.bannerSec{gap: 15px;}
.sticky{position: sticky; top:10px;z-index: 100;}
.contactFormSection{width:100%;height: auto ; position: relative; overflow: hidden; padding: 70px 0px;}
.contactInfoForm{
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08); border: 1px solid #edeef5; border-radius: 10px;
}
.contactFormSection .col-md-6{position: static !important;}
.contactFormSection p{font-size: 18px !important; font-size: 18px !important;
  margin: 10px 0px 45px 0px !important;}
.contactFormSection h3{font-size:35px;font-weight: bold; text-align: center;}



.contactFormSection form{width: 100%; height: auto; 
  position: relative; 
  background: #F6F9FC;
  padding: 30px 25px;}

.contactFormSection form input{  width: 100%; height: 100%; background: #fff!important; outline: none !important;  padding-left: 40px; padding-right: 200px;}
.contactFormSection form button{background-color: #6c757d!important; font-size: 14px !important; padding: 12px 25px !important; color: #fff !important; text-transform: capitalize !important; font-weight: 600 !important;}
.contactInfo{padding: 30px 15px 0; background-color:#71778e ;}
.contactForm button{border: 1px solid #71778e !important;}
.size11{    width: 355px;    padding: 10px 30px;
  max-width: 100%;}
footer{width: 100%; height: 500px; margin:auto; background: #fff; padding:25px 0px;}
footer .topInfo{border-bottom: 1px solid rgba(0,0,0,0.1); padding-bottom: 20px;}
footer .topInfo .col{padding: 10px 15px; padding-left: 40px; border-right: 1px solid rgba(0,0,0,0.2);}
footer .topInfo .col:last-child{border-right: 0px;}
footer .topInfo .col svg{font-size: 26px;}
footer .topInfo .col span{font-size: 14px; font-weight: 600;}

footer .linksWrap h5{font-size: 15px; margin-bottom: 20px;font-weight: 600;}
footer .linksWrap ul li{list-style:none !important; margin-bottom: 10px;}
footer .linksWrap ul li a{color: #71778e; font-size: 14px; text-decoration: none;}
footer .linksWrap ul li a:hover{text-decoration: none !important;}

footer .copyright ul li a{display: flex; align-items: center; justify-content: center;width: 35px; height: 35px; border-radius: 50%; border: 1px solid rgba(0,0,0,0.2); transition: all 0.3s ease-in-out;}
footer .copyright ul li a svg{font-size: 16px; color: rgba(0,0,0,0.8);}
footer .copyright ul li a:hover{background: #cdc1b6 !important;}
footer .copyright ul li a:hover svg{color: #fff !important;}

.productModal .MuiPaper-elevation{padding: 25px; position:relative; max-width:900px !important; width:900px !important;}
.productModal h4{font-size: 20px !important;color:rgpa(0, 0, 0, 0.8) !important;}

.productZoom{width: 100%; height: auto;  padding: 0px; overflow: hidden; border-radius: 15px;}
.productZoom .badge{position: absolute; top: 20px; left: 20px; z-index: 100;}
.zoomSliderBig{width: 100% !important}
.zoomSlider .slick-slide{ padding-right: 20px !important;}
.zoomSlider *{outline: none !important;}
.zoomSlider .item{border: 2px solid transparent !important; overflow: hidden; border-radius: 15px; cursor: pointer;}
.zoomSlider .slick-current.item{border: 2px solid #3bb77e !important;}
.zoomSlider .slick-arrow{top: 55% !important; zoom: 80% !important;}
.zoomSlider .slick-arrow .slick-next{right: -2% !important;}
.zoomSlider .slick-arrow .slick-prev{left: -5% !important;}

.zoomSlider{padding-top: 10px;}
.quantityDrop{width: 150px; gap:20px;}
.quantityDrop button{min-width: 40px !important; width:40px !important; height: 40px !important; border-radius: 50% !important; background: #edeef5 !important; border: 1px solid rgba(0, 0, 0, 0.1)!important; color: #000;}
.quantityDrop button svg{color:#000 !important}
.quantityDrop input{width: 30px; border: 0px; background: transparent; outline: none !important;text-align: center;}

.productModal .actions button{color: #000 !important; border: 1px solid rgba(0,0,0,0.1) !important; font-size: 10px !important;}
.productModal .actions button svg{font-size: 15px !important;}
.homeCat .swiper .item {
  border: 1px solid #cdc1b6;
  border-radius: 10px;
  padding: 25px 40px;
  transition: transform 0.3s, box-shadow 0.3s;
  
}
.homeCat .swiper .item h6{font-size: 25px;}
.homeCat .swiper .item img {
  object-fit: cover;
}
.homeCat .swiper .item:hover{background-color:#cdc1b6; color: #fff; transform: translateY(-10px);
  box-shadow: 0 4px 20px #cdc1b6;}
.team-card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
  fill: #cdc1b6 !important;
  font-size: 35px !important;
}
.css-8t49rw-MuiStepConnector-line{
  border-color: #cdc1b6 !important;
}
.css-1hv8oq8-MuiStepLabel-label{
  font-size: 20px !important;
}

.viewAllBtn:hover{
  background-color: #cdc1b6 !important;
  color: #fff !important;
}
.section.companyShortInto{
  padding: 25px 0px;
  width: 100%;
 
}
.team{
  padding: 25px 0px;
  width: 100%;
 
}

.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}

.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;


}

.myCarousel {
  background: #e8ecef;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 9%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #e8ecef;
  height: 286px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

.jobsInfo{
 margin: 25px 0;
    margin-right: 30px;
    width: 100%;
}



.jobsInfo:hover {
  box-shadow: 0 4px 20px #cdc1b6;
 
}


.rightSubtitle{
  margin-left: auto !important;
}

.site-section {
  padding: 5rem 0;
}

.section-stack {
  position: relative;
}
@supports ((display: -ms-grid) or (display: grid)) {
  .section-stack .image-stack {
   
      display: grid;
      position: relative;
    
      grid-template-columns: repeat(12, 1fr);
  }
}
@supports ((display: -ms-grid) or (display: grid)) {
  .section-stack .image-stack__item--top {
      -ms-grid-row: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-column-span: 8;
      grid-column: 1 / span 8;
      padding-top: 20%;
      width: 100%;
  }
}
.section-stack .image-stack__item--top {
  float: left;
  width: 66%;
  margin-right: -100%;
  position: relative;
  z-index: 1;
}
.section-stack .image-stack__item--top img {
  border: 4px solid #ffffff;
  -webkit-box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}
.section-stack img {
  width: 100%;
  display: block;

}
@supports ((display: -ms-grid) or (display: grid)) {
  .section-stack .image-stack__item--bottom {
      grid-column: 4 / -1;
      -ms-grid-row: 1;
      grid-row: 1;
      width: 100%;
  }
}
.section-stack .image-stack__item--bottom {
  float: right;
  width: 75%;
}
.signupForm{
padding: 35px 0px;
width: 100%;
/* background: antiquewhite; */
border-bottom: 1px solid #ddd;
}

.ks-page-header {
  background: #000000;  /* fallback for old browsers */
  background: radial-gradient(circle at center, rgba(0,0,0, 0.8), rgba(0,0,0, 0.8), rgba(0,0,0, 0.7), rgba(0,0,0, 0.5), rgba(0,0,0, 0.2), rgba(0,0,0, 0)), url('https://picsum.photos/id/0/1280/720');
 background-attachment: scroll;
 background-repeat: no-repeat;
 background-position: center center;
 background-size: cover;
 
 display: flex;
 height: 500px;
 height: 100vh;
}
.ks-page-title {

  margin: auto;
 }

 @supports (initial-letter: 4) or (-webkit-initial-letter: 4) {
  .ks-fancy-letter::first-letter {
   color: #1e1e1e;
   font-weight: bold;
   margin-right: 0.5em;
   -webkit-initial-letter: 4;
   initial-letter: 4;
 }
}
.a2a_floating_style.a2a_default_style { 
  border-radius: 0;
  bottom:0; 
  left:50%; 
  transform:translateX(-50%);
}

@media screen and (min-width: 992px) {
  .a2a_floating_style.a2a_default_style { 
    bottom: auto;
    left:0px; 
    top:150px;
    transform:translateX(0%);
   }
  .a2a_floating_style.a2a_default_style a {
    clear: left;
    display: block;
    overflow: hidden;
    padding: 4px;
    text-decoration: none;
 }
}
.menu-icon {
  display: none;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
 
  text-decoration: none;
}
.navpart1{
  display: none;
}
.part3 span a {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.userTab{padding-top: 10px;}
.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}
.part2{
  display: flex;
  align-items: center;
}
.menu-icon svg{font-size: 18px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;

  }
}

@media (max-width: 600px) {
  .logoWrapper{
  display: block !important;
  margin: 0 auto !important;
padding: 10px !important;


  }
  .aboutBio1{padding: 0px 15px;}
  .wrapper{width: 100% !important;}
  .aboutDetails{width: 100% !important;
  padding: 0px 15px !important;}
  .lastSection{padding: 0px 15px !important;;}
  .part2{
    display: block !important;
  }
  .part2 .headerSearch{width: 95%; margin: 10px;}
  .logoWrapper img{
    display: block !important;
    margin: 0 auto !important;

    }
  .menu-icon {
    display: block !important;
    cursor: pointer;
    position: absolute !important;
    top: -62px !important;
    right: 40px !important;
  }


  .nav-elements {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    width: 0px;
    height: calc(100vh - 0px);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 100;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  }

  

  .nav-elements.active {
    width: 270px;
    overflow: scroll;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }
.nav-elements ul li:first-child{
  margin-top: 22px;
}
  .nav-elements ul li {
    margin-right: unset !important;
    padding-bottom: 10px !important;
    margin-left: 15px !important;
   
  }
}

.newsLetterSection{width:100%;height: auto ;background: #3b433d; position: relative; overflow: hidden; padding: 70px 0px;}
.newsLetterSection .col-md-6{position: static !important;}
.newsLetterSection p{font-size: 18px; margin: 10px 0px;}
.newsLetterSection h3{font-size:35px;font-weight: bold;}
.newsLetterSection p.text-light{color:#fff !important;opacity: 0.5;font-size: 15px;}
.newsLetterSection img{position:absolute;bottom: 0px;right: 0px;}
.newsLetterSection form{width: 100%; height: 60px; background: #fff; padding: 10px; position: relative;border-radius: 5px;}
.newsLetterSection form svg{position: absolute; top: 17px; left:10px; font-size: 25px; opacity: 0.3 ;}
.newsLetterSection form input{  width: 100%; height: 100%; background: #fff!important; outline: none !important; border: 0px !important; padding-left: 40px; padding-right: 200px;}
.newsLetterSection form button{position: absolute; top:6px;right: 7px;background-color: #3b433d!important; font-size: 14px !important; padding: 12px 25px !important; color: #fff !important; border: 1px solid #3b433d; text-transform: capitalize !important; font-weight: 600 !important;}
.postWrapper{
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08); border: 1px solid #edeef5; border-radius: 10px; padding: 25px; margin: 25px 0px;
}
.postDetails{padding: 15px 0px;}
.postDetails h3{font-size: 25px; color: rgba(0, 0, 0, 0.8); font-weight: 800;}
.postDetails p{font-size: 18px; color: rgba(0, 0, 0, 0.4); font-weight: 500;}
.postDetails button{ width: 15%; text-transform: capitalize !important; color: #fff !important; justify-content: flex-start; padding: 15px 20px; background-color:#3b433d;}
.postDetails button:hover{
  background-color:#3b433d;
}
.postDetails button a {
  color: #fff !important;
  text-decoration: none !important;
}
.postDetails button a:hover{
  background-color:#3b433d;
}

.sidePostView{
  margin: 25px 0px;}
.sidePostView input{width: 100%;
  padding: 10px 25px;
  background: #f3f4f7;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  outline: none;
  color: #202435;
  font-size: 18px;
  transition: all 0.2s cubic-bezier(0.28, 0.12, 0.22, 1);}
  .widgetPopularPost{
    margin-top: 25px;
  }
  .widgetPost{border: 1px solid #edeef5;
    border-radius: 7px;
    padding: 30px;
    }
    .widgetPost article a{
      word-wrap: break-word;
      font-size: 18px;
      color: #000;
      margin-bottom: 20px;
      text-decoration: none;
    }
.widgetPost span{
  position: relative;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}
    .widgetPost span { align-items: center; justify-content: center; min-width: 45px !important;height: 45px !important;width: 40px !important; border-radius: 50% !important;border:1px solid rgba(0,0,0,0.4)!important;color: #000;background: #fff;

    }
    .widgetPost span svg{font-size: 22px;}
    .widgetTitle{
font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0;
    margin-bottom: 1.25rem;
    }
    .tags{
   
    margin-top: 25px;
    }
    .tags h3{
      font-size: 25px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0;
      margin-bottom: 1.25rem;
  
    }
    .widgetTags a{
      color: #000;
      background-color: transparent;
      border: 1px solid #edeef5;
      height: 32px;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 16px !important;
      margin-right: 10px;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
   
    }
    .paginationPost{
      text-align: center !important;
    }
    
    .paginationPost nav{
      display: inline-block !important;
      border-bottom: 0 !important;
    }
    .paginationPost nav ul li a{
     font-size: 18px !important;
    }
  .wrapper {
    height: 600px;
    display: flex;
    gap: 20px;
   }
  
  .aboutDetails span{    padding: 20px 0px;
    font-size: 25px;
    font-weight: 600;}
    .aboutDetails h3{
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 25px;
      font-weight: 600;
    }
  
  
  .box img {
    width: 100%;
    height: 100%;
  }
  
  .box1 img {
    object-fit: cover;
  }
  
  .box2 img {
    object-fit: contain;
  }
  
  .box3 img {
     height: 100%;
      max-width: 100%;
      object-fit: cover;
      width: auto;
      /* border: 1px solid #ddd; */
      border-radius: 7px;
      margin-left: -36px;
      padding: 10px 0px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  }
  .lastSection {
    margin-top: 25px;
}
  .aboutBio1 {
    font-size: 17px;
    padding-bottom: 20px;
}
.aboutBio2{padding: 20px;}
.aboutBio2 h3{
  font-weight: 600;
  line-height: 1.2em;
  font-size: 25px;
}
.aboutBio2 p{
  padding: 20px 0px;
}

.dashboardWrapper{
  padding: 40px;
  margin: 20px;
}
.dashboardWrapper h2{
 padding: 10px 0px 20px 0;
}
.dashboardWrapper table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.dashboardWrapper td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.dashboardWrapper tr:nth-child(even) {
  background-color: #dddddd;
}